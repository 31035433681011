<template>
  <div class="container">
    <div class="navBar">
      <div class="navItem active">新产品需求</div>
      <div class="navItem" @click="$router.push('/newProduct')">产品信息咨询</div>
    </div>
    <div class="pagemain">
      <div class="Qamain" v-for="(item, index) in filterList" :key="item.TopicId">
        <div class="Qaicon icon1"  :class="{'icon2': (index + 1) % 2 == 0, 'icon3': (index + 1) % 3 == 0}">Q</div>
        <div class="Qatitle">{{ item.Name }}</div>
        <div class="Qaselect">
          <van-radio-group v-model="item.Answer" v-if="item.Type == 1" @change="radioChange($event, index)">
            <van-radio class="checkbox" icon-size="20" checked-color="#CA001B" v-for="option in item.ol" :key="option.OptionId" :name="option.OptionId">{{ option.Op_Name }}</van-radio>
          </van-radio-group>

          <van-checkbox-group v-model="item.Answer" v-if="item.Type == 2" @change="checkboxChange($event, index)">
            <van-checkbox class="checkbox" shape="square" icon-size="18" checked-color="#CA001B" v-for="option in item.ol" :key="option.OptionId" :name="option.OptionId">{{ option.Op_Name }}</van-checkbox>
          </van-checkbox-group>

          <p class="tips" v-if="item.TopicId == 6">常见长度：W8557-17mm、VCP311H-22mm、VCP345H-36mm等</p>
          <p class="tips" v-if="item.TopicId == 12">常见长度：VCP771D-45cm、VCP433H-70cm、VCP345H-90cm等</p>
          <input class="input" type="text" :maxlength="item.TopicId == 6 || item.TopicId == 12 ? '' : 6" v-model="item.Content" v-if="item.Type == 3 || item.isOther" :placeholder="item.TopicId == 6 || item.TopicId == 12 ? '' : '最多可输入6个字'" />
        </div>
      </div>
    </div>
    <div class="btnBox">
      <van-button plain type="primary" class="back" @click="back">查看我的问卷</van-button>
      <van-button type="danger" class="submit" @click="submit">提交</van-button>
    </div>

    <van-overlay :show="showOverlay" :lock-scroll="false">
      <div class="wrapper" @click.stop>
        <div class="wrapperContent">
          <p class="content">
            您好！<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;为了更真实全面地了解您对伤口闭合相关产品的使用情况及需求，我们将以表单的形式请您回答与产品使用和需求相关的若干问题，同时为了方便我们与您进行联系和回访，我们将收集您的姓名、工作单位（任职医院）和联系电话。我们承诺上述收集的信息仅用于问卷调查和回访目的，并将妥善保护上述信息。请您在提交表单中勿填写或提交任何自己或他人（如患者）的个人信息。<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;请您知悉公司*只接受非保密信息，例如对已上市产品进行观察或通过公知技术进行测量后即可得到的清楚且显而易见的产品参数或特征；已公开展示或已通过其他方式向公众提供的产品市场信息；来自专利、已公布专利申请或公开出版物的信息。除本表单指定提供的信息外，公司不接受其他任何内容或形式的信息或材料（无论是保密的还是非保密的）。<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;本提交基于自愿，公司接受或评估本提交并不建立或暗示任何关系，并且公司有权保留本提交。公司无义务说明其就本提交做出任何决定的原因，且无义务披露与本提交相关的任何过去或现在或未来的活动、计划，亦无义务就本提交提供奖励、奖酬或补偿。<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;请确认您有充分的权利进行提交、披露信息和做出确认。您是该等所有信息的唯一所有者，没有他人就该等信息被授予或获得权利或利益；如果您不是唯一所有者，那么您享有充分的权利，并根据公司的要求指定来源或共有人。<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;*公司指强生（上海）医疗器材有限公司和强生（中国）医疗器材有限公司及其关联公司。<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;您点击“确认”表示您确认并同意上述内容，且同意我们收集和使用上述信息用于问卷调查和回访。
          </p>
          <div class="agreeBtn">
            <div class="btn" @click="agree">确认</div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
export default {
  name: 'ProductFeedback',
  data() {
    return {
      firstQuestion: null,
      list: [],
      filterList: [],
      showOverlay: false,
      isSubmit: false
    }
  },
  created() {
    this.getPLModel();
    this.getList();
  },
  activated() {
    this.isSubmit = false;
  },
  methods: {
    getPLModel() {
      this.$axios.get("/Api/Api/Web/Answer/GetPLModel").then((res) => {
        if(res.Data == null) {
          this.showOverlay = true;
        }
      })
    },
    getList() {
      this.$axios.get("/Api/Api/Web/Answer/GetList").then((res) => {
        this.list = res.Data;
        this.list.forEach(item => {
          if(item.Type == 1) {
            this.$set(item, 'Answer', '');
          } else {
            this.$set(item, 'Answer', []);
          }
          this.$set(item, 'Content', '');
          this.$set(item, 'isOther', false);
        });
        console.log(this.list);

        this.firstQuestion = JSON.parse(JSON.stringify(this.list[0]));
        this.filterList.push(this.firstQuestion);
      });
    },
    radioChange(val, index) {
      let arr = [];
      arr.push(val);

      this.showOther(arr, index);
    },
    checkboxChange(arr, index) {
      this.showOther(arr, index);
    },
    showOther(arr, index) {
      let checkArr = [];
      arr.forEach(el => {
        this.filterList[index].ol.forEach(item => {
          if(el == item.OptionId) {
            checkArr.push(item);
          }
        })
      })

      if(checkArr.some(item => item.Op_Name == '其他')) {
        this.$set(this.filterList[index], 'isOther', true);
        this.$set(this.filterList[index], 'Content', '');
      } else {
        this.$set(this.filterList[index], 'isOther', false);
      }

      this.filterQuestion(arr, index);
    },
    filterQuestion(arr, index) {
      // 查找关联选项的问题
      let filterArr = [];
      let tempList = JSON.parse(JSON.stringify(this.list))
      arr.forEach(option => {
        let arr = tempList.filter(item => option == item.POId);
        filterArr = filterArr.concat(arr);
      })

      this.filterList = this.filterList.concat(filterArr);

      // 过滤掉相同问题
      let map = new Map();
      for (let item of this.filterList) {
          if (!map.has(item.TopicId)) {
            map.set(item.TopicId, item);
          };
      };
      let mapArr = [...map.values()];
      this.filterList = mapArr;

      // 选项取消选中时，移除选项关联问题
      let olArr = [];
      let excludeArr = [];
      this.filterList[index].ol.forEach(el => {
        olArr.push(el.OptionId)
      })
      excludeArr = this.getArrDifference(arr, olArr);

      let ol = [];
      excludeArr.forEach(el => {
        let index = this.filterList.findIndex(item => item.POId == el);
        if(index != -1) {
          ol = this.filterList[index].ol;
          this.filterList.splice(index, 1);
        }

        ol.forEach(olOption => {
            let olIndex = this.filterList.findIndex(item => item.POId == olOption.OptionId);
            if(olIndex != -1) {
              this.filterList.splice(olIndex, 1);
            }
        })
      })

      this.firstQuestion.ol.forEach(option => {
        this.filterList.forEach(item => {
          if(option.OptionId == item.POId) {
            item.level = 2;
          }
        })
      })

      let level2 = [];
      let excludeLevel = [];
      this.filterList.forEach((item, index) => {
        if(item.level && item.level == 2) {
          level2.push(item);
        } else {
          excludeLevel.push(item);
        }
      })
      this.filterList = excludeLevel;

      let i = 1;
      level2.forEach(item => {
        this.filterList.splice(i, 0, item);
        i++;
      })
      console.log(this.filterList)
    },
    // filterQuestion1(arr, index) {
    //   let filterArr = [];
    //   this.filterList.forEach(el => {
    //     if(el.Answer instanceof Array) {
    //       el.Answer.forEach(option => {
    //         console.log(option)
    //         let arr = this.list.filter(item => option == item.POId);
    //         filterArr = filterArr.concat(arr);
    //       })
    //     } else {
    //       let arr = this.list.filter(item => el.Answer == item.POId);
    //       filterArr = filterArr.concat(arr);
    //     }
    //   })
    //   this.filterList = this.filterList.concat(filterArr);
    //   console.log(this.filterList);

    //   let map = new Map();
    //   for (let item of this.filterList) {
    //       if (!map.has(item.TopicId)) {
    //         map.set(item.TopicId, item);
    //       };
    //   };
    //   let mapArr = [...map.values()];
    //   this.filterList = mapArr;
    //   console.log(this.filterList);

    //   // 选项取消选中时，移除选项
    //   let olArr = [];
    //   let excludeArr = [];
    //   this.list[index].ol.forEach(el => {
    //     olArr.push(el.OptionId)
    //   })
    //   excludeArr = this.getArrDifference(arr, olArr);
    //   console.log(excludeArr)
    //   excludeArr.forEach(el => {
    //     let index = this.filterList.findIndex(item => item.POId == el)
    //     if(index != -1) {
    //       this.filterList.splice(index, 1);
    //     }
    //   })
    // },
    getArrDifference(arr1, arr2) {
      // filter(v, i, arr) => (当前元素的值，下标，当前元素属于的数组对象)
      return arr1.concat(arr2).filter(function(v, i, arr) {
        // 判断前后位置是否相同，相同就表示只有这一个值
        return arr.indexOf(v) === arr.lastIndexOf(v);
      });
    },
    submit() {
      let flag = true;
      let data = [];
      this.filterList.forEach(item => {
        if(item.Type == 1) {
          if(item.Answer == '') {
            flag = false;
            this.$toast('选项不能为空！');
            return false;
          }
        } else if(item.Type == 2) {
          if(item.Answer.length == 0) {
            flag = false;
            this.$toast('选项不能为空！');
            return false;
          }
          if(item.isOther) {
            if(!item.Content) {
            flag = false;
              this.$toast('填写内容不能为空！');
              return false;
            }
          }
        } else if(item.Type == 3) {
          if(!item.Content) {
            flag = false;
            this.$toast('填写内容不能为空！');
            return false;
          }
        }

        data.push({
          tid: item.TopicId,
          Oids: item.Type == 1 ? [item.Answer] : item.Type == 2 ? item.Answer : [],
          Content: item.Content
        })
      })
      // console.log(data);

      if(flag && !this.isSubmit) {
        this.isSubmit = true;
        this.$axios.post("/Api/Api/Web/Answer/Add", data).then((res) => {
          this.$toast({
            type: 'success',
            message	: '提交成功',
            onClose: () => {
              this.filterList = [];
              this.firstQuestion = JSON.parse(JSON.stringify(this.list[0]));
              this.filterList.push(this.firstQuestion);
              this.$router.push('/productFeedbackList');
            }
          });
        })
      }
    },
    back() {
      this.$router.push('/productFeedbackList');
    },
    agree() {
      this.$axios.post("/Api/Api/Web/Answer/PLAdd").then((res) => {
        this.showOverlay = false;
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  width: 100%;
  min-height: 100%;
  background: #f9f9f9;
  padding: 60px 20px 80px;
  box-sizing: border-box;
  .navBar {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9;
    width: 100%;
    height: 45px;
    // border: 1px solid red;
    background-color: #FFFFFF;
    box-sizing: border-box;
    display: flex;
    box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.1);
    .navItem {
      flex: 1;
      text-align: center;
      line-height: 45px;
      font-size: 15px;
      color: #333333;
    }
    .active {
      background-color: #CA001B;
      color: #FFFFFF;
    }
  }
  .pagemain {
    margin: auto;
    padding: 1em 0;
    padding-left: 1.5em;
    border-left: 1px dashed #a7a7a7;
    box-sizing: border-box;
  }
  .Qamain {
    background: #fff;
    padding: 15px;
    width: 90%;
    border-bottom-left-radius: 1.5em;
    border-top-right-radius: 1.5em;
    border-bottom-right-radius: 1.5em;
    box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  .Qamain:not(:last-child) {
    margin-bottom: 2em;
  }
  .Qaicon {
    position: absolute;
    left: -2.5em;
    top: 0;
    background: #fff;
    color: #fff;
    padding: 0.5em;
    border-radius: 5em;
    width: 1em;
    height: 1em;
    font-size: 15px;
    line-height: 0.9em;
    text-align: center;
  }
  .icon1 {
    background: #ff5896 !important;
  }
  .icon2 {
    background: #b53fcb !important;
  }
  .icon3 {
    background: #7d9ce3 !important;
  }
  .Qatitle {
    font-size: 14px;
    margin-bottom: 10px;
    text-align: left;
  }
  .Qaselect {
    font-size: 13px;
    .checkbox {
      margin-bottom: 5px;
    }
  }
  .input {
    width: 90%;
    height: 28px;
    border: 1px solid #CA001B;
    border-radius: 4px;
    padding: 0 8px;
    box-sizing: border-box;
    margin: 0 auto;
    font-size: 13px;
  }
  /*webkit 内核浏览器*/
  input::-webkit-input-placeholder{
    font-size: 13px;
    color: #999999;
  }
  /*Mozilla Firefox 19+*/
  input::-moz-placeholder{
    font-size: 13px;
    color: #999999;
  }
  /*Mozilla Firefox 4 to 18*/
  input:-moz-placeholder{
    font-size: 13px;
    color: #999999;
  }
  /*Internet Explorer 10+*/
  input:-ms-input-placeholder{
    font-size: 13px;
    color: #999999;
  }
  .tips {
    font-size: 12px;
    color: #CCCCCC;
    margin-bottom: 5px;
  }
  .btnBox {
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    padding: 0 20px;
    box-sizing: border-box;
  }
  .back {
    width: 48%;
    border-radius: 5px;
    color: #CA001B;
    border-color: #CA001B;
    background: #f9f9f9;
  }
  .submit {
    color: #fff;
    width: 48%;
    border-radius: 5px;
    background-color: #CA001B;
    border-color: #CA001B;
  }
}
.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .wrapperContent {
      position: relative;
      width: 80%;
      height: 60%;
      padding: 15px;
      box-sizing: border-box;
      background-color: #fff;
      font-size: 13px;
      border-radius: 6px;
      .content {
        height: 89%;
        overflow: scroll;
      }
    }
    .agreeBtn {
      margin-top: 10px;
      .btn {
        width: 40px;
        height: 30px;
        line-height: 30px;
        padding: 0 10px;
        background-color: #CA001B;
        color: #FFFFFF;
        text-align: center;
        border-radius: 4px;
        margin: 0 auto;
      }
    }
}
.van-overlay {
  z-index: 99;
}
</style>